import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { getCookieDomain } from '../../../lib/helper';

export function CookieBanner() {
  const initialOpenCookieBanner = true;
  const [isOpenCookieBanner, setIsOpenCookieBanner] = useState(
    initialOpenCookieBanner
  );
  const { t, lang } = useTranslation('common');

  useEffect(() => {
    const rcPDPA = Cookies.get('Rc_PDPA');
    setIsOpenCookieBanner(!rcPDPA);
  }, []);

  const handleAcceptCookie = () => {
    const now = new Date();
    const cookieValue = {
      categories: [],
      revision: 0,
      data: null,
      rfc_cookie: true,
      consent_date: now,
      last_consent_update: now,
    };
    const domain = getCookieDomain();
    Cookies.set('Rc_PDPA', JSON.stringify(cookieValue), {
      expires: 30,
      path: '/',
      domain: domain.startsWith('.') ? domain : '.' + domain,
    });
    setIsOpenCookieBanner(false);
  };

  return (
    <>
      {isOpenCookieBanner && (
        <div className="fixed bottom-0 w-full flex justify-center z-[21370001] p-[10px]">
          <div className="flex flex-col md:flex-row bg-white py-[20px] px-[1rem] max-w-[46rem] rounded-[16px] shadow-[0px_7px_15px_rgba(42,49,203,0.2)]">
            <div className="text-sm leading-[21px]">
              <div className={clsx('text-left font-size-sm')}>
                {t('cookieBannerContent')}
                <a
                  className="text-primary"
                  target="_blank"
                  href={`${lang === 'en' ? '/en/cookie-policy' : '/th/cookie-policy'}`}
                >
                  {t('cookieBannerLink')}
                </a>
              </div>
            </div>
            <button
              data-testid="btn-accept-cookies"
              className="md:min-w-[7rem] md:ml-[1rem] mt-[1rem] md:mt-0 font-bold uppercase bg-buttonPrimaryBg hover:bg-buttonPrimaryBgHover text-white rounded-[10px] py-[13px] px-[27px] text-sm h-[50px]"
              onClick={handleAcceptCookie}
            >
              {t('cookieBannerBtnLabel')}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default CookieBanner;
